import { Link } from 'gatsby'
import React from 'react'
import { Box, Flex } from 'reflexbox'
import Layout from 'src/components/Layout/Layout'

const NotFoundPage = ({ pageContext }) => {
  return (
    <Layout>
      <Flex height={'100vh'} width={1} alignItems={'center'} justifyContent={'center'} textAlign={'center'} flexDirection={'column'}><Box as="h1" fontSize={'38px'} marginBottom={8}>Page not found</Box><Link className='button' to="/">Go to startpage</Link></Flex>
    </Layout>
  )
}

export default NotFoundPage
